import { useMemo, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Multiselect,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useGetTopSearchDataV2 } from "../../../../../../components/CommonSearch/api/hooks/useGetTopSearchDataV2";
import { Vertical } from "../../../../../../types/verticalTypes";
import { convertData } from "../../../../../../components/CommonSearch/CommonSearch";

export function AddTitlesToFranchiseModal({
  visible,
  onDiscard,
  onSubmit,
  excludeIpIds = [],
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useGetTopSearchDataV2({
    q: query,
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
  });

  const searchItems = useMemo(() => {
    return (data && data.results?.length > 0)
      ? data.results.map((item) => ({
          ...item,
          disabled: excludeIpIds.includes(item.ip_id),
        }))
      : [];
  }, [data, excludeIpIds]);

  return (
    <Modal
      size="medium"
      visible={visible}
      onDismiss={onDiscard}
      header={"Add titles to franchise"}
      closeAriaLabel="Close dialog"
      footer={
        <>
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => {
                  setSelectedItems([]);
                  setQuery("");
                  onDiscard();
                }}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                data-testid="submit"
                onClick={() => {
                  onSubmit(selectedItems?.map((i) => i.value));
                  setLoading(true);
                }}
                disabled={selectedItems.length === 0}
                loading={loading}
              >
                Add
              </Button>
            </SpaceBetween>
          </Box>
        </>
      }
    >
      <Multiselect
        onChange={({ detail }) => setSelectedItems(detail.selectedOptions)}
        onLoadItems={({ detail }) => setQuery(detail?.filteringText)}
        selectedOptions={selectedItems}
        options={
          searchItems && searchItems?.length > 0 ? searchItems.map(item => ({ ...item, ...convertData([item])[0] })) : []
        }
        placeholder="Search for titles"
        filteringType="manual"
        statusType={isLoading ? "loading" : "finished"}
      />
    </Modal>
  );
}
