import { ContentLayout, ExpandableSection, Grid, Header, SpaceBetween, Select } from "@cloudscape-design/components";
import React, { useState } from "react";
import { NavItemsWithId, navItems } from "../../../layouts/common/menu/side-menu";
import OTTWidget from "../components/OTTWidget";
import TrendingWidget from "../components/TrendingWidget";
import { Layout } from "../Layout";
import { GRPageCards } from "./GRPageCards";

const title = "Overview";
const gridDefinition = Array.from({ length: 3 }, () => ({
  colspan: { default: 12, s: 4 },
}));

// Define base fields and add time-period specific fields dynamically
const baseFields = "s.ip_id, s.ip, s.image_url, s.production, s.providers, s.release_date";
const getFields = (timePeriod: 'daily' | 'weekly' | 'monthly') => {
  const periodFields = {
    daily: "s.daily_rank, s.daily_rank_change, s.daily_rank_trend, s.month_score",
    weekly: "s.weekly_rank, s.weekly_rank_change, s.weekly_rank_trend, s.month_score",
    monthly: "s.monthly_rank, s.monthly_rank_change, s.monthly_rank_trend, s.month_score"
  }[timePeriod];
  
  return `${baseFields}, ${periodFields}`;
};

// Endpoint generator with time period
const getRisingEndpoint = (vertical: 'movies' | 'series' | 'gaming', timePeriod: 'daily' | 'weekly' | 'monthly') => {
  const endpoints = {
    movies: `/pql?key=platform/global_movies_${timePeriod[0]}_rising&file_type=P`,
    series: `/pql?key=platform/global_series_${timePeriod[0]}_rising&file_type=P`,
    gaming: `/pql?key=platform/global_gaming_${timePeriod[0]}_rising&file_type=P`
  };
  return `${endpoints[vertical]}&size=10`;
};

// Endpoint generator with time period
const getTopEndpoint = (vertical: 'movies' | 'series' | 'gaming', timePeriod: 'daily' | 'weekly' | 'monthly') => {
  const endpoints = {
    movies: `/pql?key=platform/global_movies_${timePeriod[0]}_top&file_type=P`,
    series: `/pql?key=platform/global_series_${timePeriod[0]}_top&file_type=P`,
    gaming: `/pql?key=platform/global_gaming_${timePeriod[0]}_top&file_type=P`
  };
  return `${endpoints[vertical]}&size=10&sort=${timePeriod}_rank:asc`;
};

// Payload generators
const getTrendingPayload = (timePeriod: 'daily' | 'weekly' | 'monthly') => ({
  sql: `SELECT ${getFields(timePeriod)} FROM s3object s LIMIT 10`
});

const getStudioPayload = (studio: string, timePeriod: 'daily' | 'weekly' | 'monthly') => ({
  sql: `SELECT ${getFields(timePeriod)} FROM s3object s WHERE s.production LIKE '%${studio}%' LIMIT 10`
});

const generateOttPayload = (providers: string[], timePeriod: 'daily' | 'weekly' | 'monthly') => ({
  sql: `SELECT s.ip, s.ip_id, s.${timePeriod}_rank, s.image_url FROM s3object s WHERE ${
    providers.map(provider => `s.providers LIKE '%''${provider}''%'`).join(' OR ')
  } LIMIT 10`
});

// URL generators
const generateStudioViewMoreHref = (studio: string) => 
  `/dashboards/globalranking/movies?tab=table&sort=rising&production=${studio}`;

const generateProviderViewMoreHref = (provider: { name: string, keys: string[] }, dashboardVertical: string) => 
  `/dashboards/globalranking/${dashboardVertical}?tab=table&providers=${provider.keys.join(" OR ")}`;

// Constants
const ottProviders = [
  { name: "Peacock", keys: ["Peacock", "Peacock Premium", "Peacock Premium Plus"] },
  { name: "Netflix", keys: ["Netflix", "Netflix basic with Ads"] },
  { name: "HBO Max", keys: ["Max", "Max Amazon Channel"] },
  { name: "Hulu", keys: ["Hulu"] },
  { name: "Amazon Prime", keys: ["Amazon Prime Video", "Amazon Prime Video with Ads", "Amazon Video"] },
  { name: "Disney+", keys: ["Disney Plus"] },
  { name: "Apple TV+", keys: ["Apple TV Plus"] },
  { name: "Paramount+", keys: ["Paramount Plus", "Paramount Plus Apple TV Channel ", "Paramount+ Amazon Channel", "Paramount+ Roku Premium Channel"] },
];

const studios = [
  { name: "Universal Pictures", displayName: "Universal Pictures Productions" },
  { name: "DreamWorks Animation", displayName: "DreamWorks Animation Productions" },
  { name: "Blumhouse Productions", displayName: "Blumhouse Productions" }
] as const;

const timeOptions = [
  { label: "Last 1 Day", value: "daily" },
  { label: "Last 7 Days", value: "weekly" },
  { label: "Last 30 Days", value: "monthly" },
];

function GRHomePage() {
  const breadcrumbs = [{ text: "Rankings" }, { text: title }];
  const [categoryTimePeriod, setCategoryTimePeriod] = useState<'daily' | 'weekly' | 'monthly'>('weekly');
  const [studioTimePeriod, setStudioTimePeriod] = useState<'daily' | 'weekly' | 'monthly'>('weekly');
  const [movieOttTimePeriod, setMovieOttTimePeriod] = useState<'daily' | 'weekly' | 'monthly'>('weekly');
  const [seriesOttTimePeriod, setSeriesOttTimePeriod] = useState<'daily' | 'weekly' | 'monthly'>('weekly');

  const getTimePeriodLabel = (period: 'daily' | 'weekly' | 'monthly') => 
    period === 'daily' ? 'Last 1 Day' : 
    period === 'monthly' ? 'Last 30 Days' : 
    'Last 7 Days';

  const handleCategoryTimePeriodChange = ({ detail }) => {
    setCategoryTimePeriod(detail.selectedOption.value);
  };

  const handleStudioTimePeriodChange = ({ detail }) => {
    setStudioTimePeriod(detail.selectedOption.value);
  };

  const handleMovieOttTimePeriodChange = ({ detail }) => {
    setMovieOttTimePeriod(detail.selectedOption.value);
  };

  const handleSeriesOttTimePeriodChange = ({ detail }) => {
    setSeriesOttTimePeriod(detail.selectedOption.value);
  };

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      content={
        <ContentLayout
          header={
            <Header
              variant="h2"
              description="A collection of dashboards that provide insights into all available titles and their performance on various platforms"
            >
              {title}
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="m">
            <ExpandableSection
              variant="default"
              defaultExpanded
              headerText={`Trending Titles by Category (${getTimePeriodLabel(categoryTimePeriod)})`}
              headerActions={
                <Select
                  selectedOption={timeOptions.find(option => option.value === categoryTimePeriod)}
                  onChange={handleCategoryTimePeriodChange}
                  options={timeOptions}
                />
              }
            >
              <Grid gridDefinition={gridDefinition}>
                {[
                  { title: "Trending Movies", vertical: "movies" as const },
                  { title: "Trending Series", vertical: "series" as const },
                  { title: "Trending Games", vertical: "gaming" as const }
                ].map(({ title: sectionTitle, vertical }) => (
                  <TrendingWidget
                    key={vertical}
                    title={sectionTitle}
                    payload={getTrendingPayload(categoryTimePeriod)}
                    endpoint={getRisingEndpoint(vertical, categoryTimePeriod)}
                    viewMoreHref={`/dashboards/globalranking/${vertical}?tab=table&sort=rising`}
                    timePeriod={categoryTimePeriod}
                  />
                ))}
              </Grid>
            </ExpandableSection>

            <ExpandableSection 
              variant="default" 
              defaultExpanded 
              headerText={`Trending Movies by Studios (${getTimePeriodLabel(studioTimePeriod)})`}
              headerActions={
                <Select
                  selectedOption={timeOptions.find(option => option.value === studioTimePeriod)}
                  onChange={handleStudioTimePeriodChange}
                  options={timeOptions}
                />
              }
            >
              <Grid gridDefinition={gridDefinition}>
                {studios.map(studio => (
                  <TrendingWidget
                    key={studio.name}
                    title={studio.displayName}
                    payload={getStudioPayload(studio.name, studioTimePeriod)}
                    endpoint={getRisingEndpoint("movies", studioTimePeriod)}
                    viewMoreHref={generateStudioViewMoreHref(studio.name)}
                    timePeriod={studioTimePeriod}
                  />
                ))}
              </Grid>
            </ExpandableSection>

            <ExpandableSection 
              variant="default" 
              defaultExpanded 
              headerText={`Top Movies on U.S. Streaming Services (${getTimePeriodLabel(movieOttTimePeriod)})`}
              headerActions={
                <Select
                  selectedOption={timeOptions.find(option => option.value === movieOttTimePeriod)}
                  onChange={handleMovieOttTimePeriodChange}
                  options={timeOptions}
                />
              }
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xxl:grid-cols-8 gap-2">
                {ottProviders.map((provider) => (
                  <OTTWidget
                    key={provider.name}
                    title={provider.name}
                    endpoint={getTopEndpoint("movies", movieOttTimePeriod)}
                    payload={generateOttPayload(provider.keys, movieOttTimePeriod)}
                    viewMoreHref={generateProviderViewMoreHref(provider, "movies")}
                  />
                ))}
              </div>
            </ExpandableSection>

            <ExpandableSection 
              variant="default" 
              defaultExpanded 
              headerText={`Top Series on U.S. Streaming Services (${getTimePeriodLabel(seriesOttTimePeriod)})`}
              headerActions={
                <Select
                  selectedOption={timeOptions.find(option => option.value === seriesOttTimePeriod)}
                  onChange={handleSeriesOttTimePeriodChange}
                  options={timeOptions}
                />
              }
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xxl:grid-cols-8 gap-2">
                {ottProviders.map((provider) => (
                  <OTTWidget
                    key={provider.name}
                    title={provider.name}
                    endpoint={getTopEndpoint("series", seriesOttTimePeriod)}
                    payload={generateOttPayload(provider.keys, seriesOttTimePeriod)}
                    viewMoreHref={generateProviderViewMoreHref(provider, "series")}
                  />
                ))}
              </div>
            </ExpandableSection>

            <GRPageCards />
          </SpaceBetween>
        </ContentLayout>
      }
      contentType="cards"
    />
  );
}

export default GRHomePage;
